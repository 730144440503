import { IEntityBasePublic } from './base';

export interface IMemberProviderConfigBase {
  id: string | null;
}

export interface IMemberProviderSettings {
  stytch: IMemberProviderConfigBase;
  google: any; // TODO[nawu]: make proper type
  default: any; // TODO[nawu]: make proper type
}

export interface MemberInvitation {
  invited_by: string;
  invited_to: string;
}

export const MemberProviderSettingsDefaults: IMemberProviderSettings = {
  stytch: {
    id: null,
    authenticated: false,
  },
  google: {},
  default: {},
};

export interface IMemberProviderConfigBase {
  id: string | null;
  authenticated: boolean;
}

export enum EMemberRoles {
  engineering = 'engineering',
  operations = 'operations',
  customer_success = 'customer_success',
  finance = 'finance',
  other = 'other',
}

export interface IMemberPublic extends IEntityBasePublic {
  first_name: string;
  last_name: string;
  individual_id: string;
  organization_id: string;
  status: EMemberStatus;
  avatar: string;
}

export enum EMemberStatus {
  active = 'active',
  inactive = 'inactive',
}

export interface MemberAvatar {
  avatar: string;
  fallbackInitials: string;
}
