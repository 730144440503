export interface GeneralSettings {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  role: string;
  avatar: string;
  teamName: string;
  teamLogo: string;
}

export interface TeamSettings {
  individualId: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  avatar: string;
  lastActive: Date | null;
}

export interface SecuritySettings {
  // TODO[nawu]: fill this out once security pages are built
  // Define the properties of security settings
}

export interface NotificationsSettings {
  // TODO[nawu]: fill this out once notification pages are built
  // Define the properties of notifications settings
}

export interface SettingsInvoices {
  id: string;
  month: string;
  amount: number;
  status: string;
}

export interface BillingSettings {
  invoices: SettingsInvoices[];
  subscriptions: {
    id: string;
    name: string;
    resource: string;
    description: string;
    href: string;
  }[];
  availableAddOnSubscriptions: {
    id: string;
    name: string;
    resource: string;
    description: string;
    href: string;
  }[];
}

export interface RetrieveSettingsDTO {
  general: GeneralSettings;
  team: TeamSettings;
  security: SecuritySettings;
  notifications: NotificationsSettings;
  billing: BillingSettings;
}

export enum SettingsTab {
  GENERAL = 'general',
  TEAM = 'team',
  SECURITY = 'security',
  NOTIFICATIONS = 'notifications',
  BILLING = 'billing',
}
