import {
  IAuthMFAPublic,
  InvitationDisplayInfoDTO,
} from '@dash/types/lib/dashEntities';
import { UserMetadataDTO } from '@dash/types/lib/login';
import { MethodEnvironment } from '@dash/types/lib/util';
import { StateCreator, create } from 'zustand';
import { PersistOptions, createJSONStorage, persist } from 'zustand/middleware';

export interface AuthState {
  intermediateSessionToken: string;
  token: string;
  tokenEnvironment: MethodEnvironment;
  selectedEnvironment: MethodEnvironment;
  existingMFA: IAuthMFAPublic | null;
  userMetadata: UserMetadataDTO | null;
  invitation: InvitationDisplayInfoDTO | null;
  fromInvite: boolean;
  set: (values: Partial<AuthState>) => void;
}

type AuthStorePersist = (
  config: StateCreator<AuthState>,
  options: PersistOptions<Partial<AuthState>>
) => StateCreator<AuthState>;

export const authStore = create<AuthState>(
  (persist as AuthStorePersist)(
    (set) => ({
      intermediateSessionToken: '',
      token: '',
      tokenEnvironment: MethodEnvironment.Development,
      selectedEnvironment: MethodEnvironment.Development,
      existingMFA: null,
      userMetadata: null,
      invitation: null,
      fromInvite: false,
      set: (values) => set((state) => ({ ...state, ...values })),
    }),
    {
      name: 'auth-storage',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        token: state.token,
        tokenEnvironment: state.tokenEnvironment,
        selectedEnvironment: state.selectedEnvironment,
        intermediateSessionToken: state.intermediateSessionToken,
        existingMFA: state.existingMFA,
        userMetadata: state.userMetadata,
        invitation: state.invitation,
      }),
    }
  )
);

export const useAuthStore = authStore;

export default useAuthStore;
