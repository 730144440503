'use client';

import { useEffect } from 'react';
import Cohere from 'cohere-js';
import { MethodAuthServiceProvider } from '@frontend/hooks/methodAuthContext';
import { MethodDashServiceProvider } from '@frontend/hooks/methodDashContext';
import { TooltipProvider } from '@frontend/components/ui/tooltip';
import { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const CONSOLE_STYLE = {
  backgroundColor: '#000B27',
  color: '#00FFD1',
  fontSize: '16px',
  fontWeight: 'bold',
  padding: '10px',
  borderRadius: '5px',
  lineHeight: '1.5',
} as const;

const queryClient = new QueryClient();

// Types
interface ProvidersProps {
  children: ReactNode;
}

const useMethodInitialization = () => {
  useEffect(() => {
    // Initialize Cohere
    Cohere.init(process.env.NEXT_PUBLIC_COHERE_API_KEY || '');

    // Show welcome message
    const cssRule = Object.entries(CONSOLE_STYLE)
      .map(
        ([key, value]) =>
          `${key.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`)}: ${value}`
      )
      .join(';');

    setTimeout(
      console.log.bind(
        console,
        "%cWelcome to Method! If you're reading this, come join us at https://careers.methodfi.com",
        cssRule
      ),
      0
    );
  }, []);
};

export const Providers = ({ children }: ProvidersProps) => {
  useMethodInitialization();

  return (
    <QueryClientProvider client={queryClient}>
      <MethodAuthServiceProvider>
        <MethodDashServiceProvider>
          <TooltipProvider>{children}</TooltipProvider>
        </MethodDashServiceProvider>
      </MethodAuthServiceProvider>
    </QueryClientProvider>
  );
};
