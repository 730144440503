export interface MethodResponse<T> {
  success: boolean;
  data: T | null;
  message: string | null;
}

export interface MethodErrorResponse {
  statusCode: number;
  errorCode: number;
  errorMessage: string;
}

export enum MethodEnvironment {
  Development = 'Development',
  Sandbox = 'Sandbox',
  Production = 'Production',
}

export const MethodEnvironmentRank = {
  [MethodEnvironment.Development]: 0,
  [MethodEnvironment.Sandbox]: 1,
  [MethodEnvironment.Production]: 2,
};

export const MethodEnvironmentToDBValue = {
  [MethodEnvironment.Development]: 'dev',
  [MethodEnvironment.Sandbox]: 'sandbox',
  [MethodEnvironment.Production]: 'prod',
};
