import { authStore } from '@frontend/stores/authStore';
import {
  ContinueWithEmailDTO,
  AuthenticateWithTokenDTO,
  SetupNewOrganizationDTO,
  NewOrganizationDTO,
  EmailAuthDTO,
  AuthMFADTO,
  UpdateMFATOTPDTO,
  EMFAMethods,
  WebAuthnActions,
  WebAuthnAuthenticateStartResponse,
  AuthenticateMFADTO,
  ValidateISTDTO,
  EAuthMFAEnrollmentStatus,
  GetTOTPCreateInfoDTO,
} from '@dash/types/lib/dashEntities';
import {
  MethodResponse,
  INTERMEDIATE_TOKEN_HEADER,
  MethodEnvironment,
} from '@dash/types/lib/util';
import axios, { AxiosInstance } from 'axios';

export interface OnboardingState {
  intermediateSessionToken?: string;
  mfaQRCode?: string;
}

export class MethodAuthService {
  apiBase: string;
  _axios: AxiosInstance;

  constructor() {
    this.apiBase =
      process.env.NEXT_PUBLIC_BACKEND_API_URL || 'http://localhost:3001';
    this._axios = axios.create({
      baseURL: this.apiBase,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this._axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          authStore.getState().set({
            intermediateSessionToken: undefined,
            token: undefined,
            invitation: undefined,
          });
        }
        return Promise.reject(error);
      }
    );

    if (authStore.getState().token) {
      this.setSessionToken(authStore.getState().token);
    }
    if (authStore.getState().intermediateSessionToken) {
      this.setIST(authStore.getState().intermediateSessionToken);
    }
  }

  setIST(token: string) {
    this._axios.defaults.headers[INTERMEDIATE_TOKEN_HEADER] = token;
  }

  setSessionToken(token: string) {
    // TODO: Handle normal member token stuff
  }

  // TODO[dw]: Move auth into own service?
  // Auth
  async continueWithEmail(
    req: ContinueWithEmailDTO
  ): Promise<MethodResponse<null>> {
    const response = await this._axios.post(`/auth/continue_with_email`, req);
    return response.data;
  }

  async authMagicLink(
    req: AuthenticateWithTokenDTO
  ): Promise<MethodResponse<EmailAuthDTO>> {
    try {
      const response = await this._axios.post(
        `/auth/authenticate_magic_link`,
        req
      );
      const methodResponse: MethodResponse<EmailAuthDTO> = response.data;
      if (methodResponse.success && methodResponse.data?.jwt) {
        authStore.getState().set({
          intermediateSessionToken: methodResponse.data?.jwt,
          existingMFA: methodResponse.data?.existingMFA,
        });
        this.setIST(methodResponse.data?.jwt);
      }
      return methodResponse;
    } catch (error: any) {
      if (
        error &&
        typeof error === 'object' &&
        'response' in error &&
        error.response?.status === 404
      ) {
        window.location.href = '/404';
      }
      throw error;
    }
  }

  async authGoogle(
    req: AuthenticateWithTokenDTO
  ): Promise<MethodResponse<EmailAuthDTO>> {
    const response = await this._axios.post(
      `/auth/authenticate_google_oauth`,
      req
    );
    const methodResponse: MethodResponse<EmailAuthDTO> = response.data;
    if (methodResponse.success && methodResponse.data?.jwt) {
      authStore.getState().set({
        intermediateSessionToken: methodResponse.data?.jwt,
        existingMFA: methodResponse.data?.existingMFA,
      });
      this.setIST(methodResponse.data?.jwt);
    }

    return methodResponse;
  }

  async setupNewOrganization(
    req: SetupNewOrganizationDTO
  ): Promise<MethodResponse<NewOrganizationDTO>> {
    const response = await this._axios.post(`/organizations/setup_new`, req);
    return response.data;
  }

  async validateIST(
    fromInvite = false
  ): Promise<MethodResponse<ValidateISTDTO>> {
    const queryParams = fromInvite ? '?fromInvite=true' : '';
    const response = await this._axios.post(
      `/auth_mfas/validate_ist${queryParams}`
    );
    const methodResponse: MethodResponse<ValidateISTDTO> = response.data;
    if (methodResponse.success) {
      authStore.getState().set({
        existingMFA: methodResponse.data?.mfa,
        invitation: methodResponse.data?.invitation,
      });
    }
    return response.data;
  }

  async createTOTP(): Promise<MethodResponse<AuthMFADTO>> {
    const response = await this._axios.post(`/auth_mfas`, {
      method: EMFAMethods.totp,
    });
    const methodResponse: MethodResponse<AuthMFADTO> = response.data;
    if (methodResponse.success) {
      authStore.getState().set({
        existingMFA: methodResponse.data,
      });
    }

    return methodResponse;
  }

  async verifyTOTP(
    req: UpdateMFATOTPDTO
  ): Promise<MethodResponse<AuthenticateMFADTO>> {
    const response = await this._axios.put(`/auth_mfas/totp`, {
      totp: req,
    });
    const methodResponse: MethodResponse<AuthenticateMFADTO> = response.data;
    if (methodResponse.success && methodResponse.data?.jwt) {
      authStore.getState().set({
        token: methodResponse.data?.jwt,
        tokenEnvironment: methodResponse.data?.environment,
        selectedEnvironment: methodResponse.data?.environment,
        // @ts-expect-error This is literally fine not sure why ts complains
        existingMFA: authStore.getState().existingMFA
          ? {
              ...authStore.getState().existingMFA,
              enrollment_status: EAuthMFAEnrollmentStatus.completed,
            }
          : null,
        userMetadata: methodResponse.data?.userMetadata,
      });
      this.setSessionToken(methodResponse.data?.jwt);
    }

    return methodResponse;
  }

  async getTOTPCreateInfo(): Promise<MethodResponse<GetTOTPCreateInfoDTO>> {
    const response = await this._axios.get(`/auth_mfas/totp`);
    const methodResponse: MethodResponse<GetTOTPCreateInfoDTO> = response.data;

    return methodResponse;
  }

  async startWebAuthnRegistration(): Promise<MethodResponse<AuthMFADTO>> {
    const response = await this._axios.post(`/auth_mfas`, {
      method: EMFAMethods.passkey,
    });
    const methodResponse: MethodResponse<AuthMFADTO> = response.data;

    return methodResponse;
  }

  async completeWebAuthnRegistration(
    credential: string
  ): Promise<MethodResponse<AuthMFADTO>> {
    const response = await this._axios.put(`/auth_mfas/passkey`, {
      method: EMFAMethods.passkey,
      passkey: { action: WebAuthnActions.completeRegistration, credential },
    });

    return response.data;
  }

  async startWebAuthnAuthenticate(): Promise<
    MethodResponse<WebAuthnAuthenticateStartResponse>
  > {
    const response = await this._axios.put(`/auth_mfas/passkey`, {
      method: EMFAMethods.passkey,
      passkey: { action: WebAuthnActions.startAuthentication },
    });

    return response.data;
  }

  async completeWebAuthnAuthenticate(
    credential: string,
    env: MethodEnvironment,
    destinationOrganizationId?: string | null
  ): Promise<MethodResponse<AuthenticateMFADTO>> {
    const response = await this._axios.put(`/auth_mfas/passkey`, {
      method: EMFAMethods.passkey,
      destinationOrganizationId,
      passkey: {
        action: WebAuthnActions.completeAuthentication,
        credential,
        environment: env,
      },
    });
    const methodResponse: MethodResponse<AuthenticateMFADTO> = response.data;
    if (methodResponse.success && methodResponse.data?.jwt) {
      authStore.getState().set({
        token: methodResponse.data?.jwt,
        tokenEnvironment: methodResponse.data?.environment,
        selectedEnvironment: methodResponse.data?.environment,
        userMetadata: methodResponse.data?.userMetadata,
      });
      this.setSessionToken(methodResponse.data?.jwt);
    }

    return methodResponse;
  }

  // TODO[nawu]: do this better
  async switchAuthn(
    jwt: string,
    env: MethodEnvironment
  ): Promise<MethodResponse<AuthenticateMFADTO>> {
    const response = await this._axios.post(`/auth_mfas/authn`, {
      jwt,
      environment: env,
    });
    const methodResponse: MethodResponse<AuthenticateMFADTO> = response.data;
    if (methodResponse.success && methodResponse.data?.jwt) {
      authStore.getState().set({
        token: methodResponse.data?.jwt,
        // we don't set the token environment when switching env otherwise it triggers MFA
        selectedEnvironment: methodResponse.data?.environment,
        userMetadata: methodResponse.data?.userMetadata,
      });
      this.setSessionToken(methodResponse.data?.jwt);
    }

    return methodResponse;
  }

  // TODO[nawu]: Fix return type
  async getProviderSpecificCreateInfoOrNull(): Promise<MethodResponse<any>> {
    const response = await this._axios.get(
      `/auth/get_provider_specific_create_info`
    );
    return response.data;
  }
}
