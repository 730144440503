import { IEntityBasePublic } from './base';
import { IIndividualPublic } from './individual';
import { EMemberRoles, IMemberPublic } from './member';

export enum EOrganizationPolicySettings {
  ALL_ALLOWED = 'ALL_ALLOWED',
  RESTRICTED = 'RESTRICTED',
  NOT_ALLOWED = 'NOT_ALLOWED',
  OPTIONAL = 'OPTIONAL',
  REQUIRED_FOR_ALL = 'REQUIRED_FOR_ALL',
}

export enum EAuthMethods {
  magic_link = 'magic_link',
  google_oauth = 'google_oauth',
}

export enum EMFAMethods {
  totp = 'totp',
  passkey = 'passkey',
}

export interface IOrganizationProviderConfigBase {
  id: string | null;
}

export interface IOrganizationProviderSettings {
  stytch: IOrganizationProviderConfigBase;
}

export enum EOrganizationSizes {
  _1_10_employees = '1_10_employees',
  _11_50_employees = '11_50_employees',
  _51_100_employees = '51_100_employees',
  _101_250_employees = '101_250_employees',
  _251_500_employees = '251_500_employees',
  _501_employees = '501_employees',
}

export enum EOrganizationStatus {
  INACTIVE = 'inactive',
  PROVISIONING = 'provisioning',
  BACKFILL = 'backfill',
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  BLOCKED = 'blocked',
}

export enum EOrganizationHeardAboutFrom {
  social_media = 'social_media',
  friend = 'friend',
  advertisement = 'advertisement',
  event = 'event',
  other = 'other',
}

export interface IOrganizationPublic extends IEntityBasePublic {
  name: string;
  slug: string;
  mfa_policy: EOrganizationPolicySettings;
  logo_url: string;
  email_jit_provisioning: EOrganizationPolicySettings;
  email_invites: EOrganizationPolicySettings;
  email_allowed_domains: string[];
  sso_jit_provisioning: EOrganizationPolicySettings;
  auth_methods: EOrganizationPolicySettings;
  allowed_auth_methods: EAuthMethods[];
  mfa_methods: EOrganizationPolicySettings;
  allowed_mfa_methods: EMFAMethods[];
  providers: IOrganizationProviderSettings;
  organization_size: EOrganizationSizes;
  heard_about_from: EOrganizationHeardAboutFrom;
  status: EOrganizationStatus;
}

// DTOs
export interface SetupNewOrganizationDTO {
  individual_first_name?: string;
  individual_last_name?: string;
  individual_role: EMemberRoles;
  organization_name: string;
  organization_size: EOrganizationSizes;
  heard_about_from: EOrganizationHeardAboutFrom;
  logo_url: string;
}

export interface NewOrganizationDTO {
  organization: IOrganizationPublic;
  individual: IIndividualPublic;
  member: IMemberPublic;
}

export interface RetrieveOrganizationDTO {
  id: string;
}
