export interface DeveloperConfigAPIKeysDTO {
  name: string;
  key: string;
  created_at: Date;
  last_used_at: Date;
}

export interface DeveloperConfigWebhooksDTO {
  id: string;
  type: string;
  url: string;
  auth_token: string;
  deleted: boolean;
  created_at: Date;
  updated_at: Date;
}

// TODO[nawu]: figure out schema for this
export interface DeveloperConfigEventsDTO {
  id: string;
  type: string;
  data: Record<string, any>;
  created_at: Date;
}

export interface DeveloperConfigs {
  api_keys: DeveloperConfigAPIKeysDTO[];
  webhooks: DeveloperConfigWebhooksDTO[];
  events: DeveloperConfigEventsDTO[];
}

export enum DevelopersTab {
  API_KEYS = 'api_keys',
  WEBHOOKS = 'webhooks',
  EVENTS = 'events',
  LOGS = 'logs',
  BILLING = 'billing',
}
