export interface IEntityBasePublic {
  id: string;
  entity_type: EEntityTypes;
  created_at: Date;
  updated_at: Date;
}

export enum EEntityTypes {
  audit = 'audit',
  auth_mfa = 'auth_mfa',
  invoice = 'invoice',
  change_history = 'change_history',
  individual = 'individual',
  organization = 'organization',
  outbound_log = 'outbound_log',
  member = 'member',
  invitation = 'invitation',
  role = 'role',
  permission = 'permission'
}
