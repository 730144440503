import { InvitationDisplayInfoDTO } from '@dash/types/lib/dashEntities';
import { UserMetadataDTO } from '@dash/types/lib/login';
import { IEntityBasePublic } from './base';
import { EMFAMethods } from './organization';
import { MethodEnvironment } from '@dash/types/lib/util';

export enum EAuthMFAEnrollmentStatus {
  pending = 'pending',
  completed = 'completed',
}

export interface IAuthMFATotp {
  recovery_codes: string[];
  base_64_qr_code: string;
}

export interface IAuthMFAWebAuthn {
  raw_public_key_credential_creation_options?: string;
  webauthn_registration_id?: string;
}

export interface IAuthMFAPublic extends IEntityBasePublic {
  individual_id: string;
  organization_id: string;
  enrollment_status: EAuthMFAEnrollmentStatus;
  method: EMFAMethods;
}

export interface AuthMFADTO extends IAuthMFAPublic {
  raw_public_key_credential_creation_options?: string;
}

export interface ValidateISTDTO {
  mfa: AuthMFADTO | null;
  hasMember: boolean;
  invitation: InvitationDisplayInfoDTO | null;
}

// LOL Maybe we change casing for these
export interface UpdateMFATOTPDTO {
  code: string;
  environment: MethodEnvironment;
  destinationOrganizationId?: string | null;
}

export enum WebAuthnActions {
  completeRegistration = 'completeRegistration',
  startAuthentication = 'startAuthentication',
  completeAuthentication = 'completeAuthentication',
}

export interface UpdateMFAWebAuthnDTO {
  credential?: string;
  action: WebAuthnActions;
}

export interface UpdateMFADTO {
  totp?: UpdateMFATOTPDTO;
  passkey?: UpdateMFAWebAuthnDTO;
}

export interface CreateAuthMFADTO {
  method: EMFAMethods;
  passkey?: {
    domain: string;
  };
}

export interface WebAuthnAuthenticateStartResponse {
  request_id: string;
  user_id: string;
  public_key_credential_request_options: string;
  status_code: number;
}

export interface AuthenticateMFADTO {
  jwt: string;
  environment: MethodEnvironment;
  mfa?: IAuthMFAPublic;
  userMetadata?: UserMetadataDTO;
}

export interface GetTOTPCreateInfoDTO {
  mfaQRCode: string;
  recoveryCodes: string[];
}

export const STYTCH_TOTP_EXISTS_ERROR = 'pending_totp_exists';
