import React, {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { MethodAuthService } from '@frontend/services/methodAuthService';

const MethodAuthContext = createContext<MethodAuthService | undefined>(
  undefined
);

export const MethodAuthServiceProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [methodAuthService] = useState(new MethodAuthService());
  const value = useMemo(() => methodAuthService, [methodAuthService]);

  return (
    <MethodAuthContext.Provider value={value}>
      {children}
    </MethodAuthContext.Provider>
  );
};

export const useMethodAuth = () => {
  const context = useContext(MethodAuthContext);
  if (context === undefined) {
    throw new Error('MethodAuthContext must be used within a UserProvider');
  }
  return context;
};
