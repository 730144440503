import { IEntityBasePublic } from './base';
import { MemberAvatar } from './member';

export interface IInvitationPublic extends IEntityBasePublic {
  organization_id: string;
  from_member: string;
  to_member: string;
  invited_role: string;
  to_email: string;
  expiration_date: Date;
  status: InvitationStatus;
}

export interface CreateInviteDTO {
  email: string;
  first_name: string;
  last_name: string;
  invited_role: string;
}

export interface InvitationDisplayInfoDTO {
  email: string;
  to_first_name: string;
  from_first_name: string;
  org_name: string;
  existing_member_avatars: MemberAvatar[];
  total_members: number;
}

export enum InvitationStatus {
  pending = 'pending',
  accepted = 'accepted',
  expired = 'expired',
}
