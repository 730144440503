import { IAuthMFAPublic } from '@dash/types/lib/dashEntities';
import { IEntityBasePublic } from './base';

export interface IAuthPublic extends IEntityBasePublic {}

export enum EAuthFlows {
  login = 'login',
  signup = 'signup',
}

export interface ContinueWithEmailDTO {
  email: string;
}

export interface AuthenticateWithTokenDTO {
  token: string;
  mfa_id?: string;
}

export interface IntermediateSessionTokenPayload {
  individual_id: string;
  purpose: 'signup';
}

export interface EmailAuthDTO {
  members: string[];
  existingMFA: IAuthMFAPublic;
  jwt: string;
}

export enum EStytchTokenTypes {
  discovery_oauth = 'discovery_oauth', // B2B unused
  discovery = 'discovery', // B2B unused
  magic_links = 'magic_links',
  oauth = 'oauth',
}
