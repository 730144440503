'use client';

import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

export default function Page() {
  // i don't think this is needed, but if we ever somehow get here
  // we should redirect to the login page
  const router = useRouter();
  useEffect(() => {
    router.replace('/login');
  }, [router]);
  return <></>;
}
