'use client';

import { useEffect } from 'react';
import Image from 'next/image';
import * as Sentry from '@sentry/nextjs';

export default function ErrorBoundary({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error
    // TODO[dw]: Datadog error logging here
    try {
      console.error('ErrorBoundary caught an error:', error);
      Sentry.captureException(error);
    } catch (e) {
      console.debug('Error sending error to sentry', e);
    }
  }, [error]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-[#f0f8ff] text-gray-800">
      <div className="text-4xl mb-8">
        <Image
          src="/icons/method-logo.svg"
          alt="Method Logo"
          className={`rounded-md`}
          width="64"
          height="64"
          style={{ aspectRatio: '64/64' }}
        />
      </div>
      <h1 className="text-2xl font-bold mb-4">Something went wrong!</h1>
      <p className="text-md text-gray-600 mb-6">
        Please try again or reach out to your Method CSM if the issue persists
      </p>
      <button
        onClick={() => reset()}
        className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
      >
        Try again
      </button>
    </div>
  );
}
