import { authStore } from '@frontend/stores/authStore';
import {
  HEADER_METHOD_ENVIRONMENT,
  MethodEnvironment,
  MethodResponse,
} from '@dash/types/lib/util';
import axios, { AxiosInstance } from 'axios';
import {
  GetEntityProductsAndSubscriptionsDTO,
  IOctoAccount,
  IOctoEntity,
  IOctoExecuteAccountProductResponseDTO,
  IOctoMerchantDetails,
  IOctoGetAccountDTO,
  IOctoGetAccountEnhancedDTO,
  OctoGetAccountProductsAndSubscriptionsDTO,
  IOctoGetAccountsAndProductsResponse,
  IOctoGetConnectHistoryDTO,
  IOctoGetCreditScoreHistoryDTO,
  IOctoGetIdentityHistoryDTO,
  IOctoGetPaymentsDTO,
  IOctoGetPaymentsOverviewDTO,
  IOctoGetProductHistoryForAccountDTO,
  IOctoMerchant,
  IOctoSubscribeAccountResponseDTO,
  IOctoSubscribeEntityResponseDTO,
  TOctoAccountSubscriptionTypes,
  TOctoEntityProducts,
  TOctoEntitySubscriptionTypes,
  TOctoProductNames,
  TOctoAccountProducts,
  TOctoAccountSensitiveFields,
  IOctoGetAccountsOverviewDTO,
} from '@dash/types/lib/octo';
import {
  BillingSettings,
  GeneralSettings,
  TeamSettings,
} from '@dash/types/lib/dashEntities/settings';
import {
  DeveloperConfigAPIKeysDTO,
  DeveloperConfigWebhooksDTO,
  DeveloperConfigEventsDTO,
} from '@dash/types/lib/dashEntities';
import { CreateInviteValidatedDTO } from '@backend/invitation/dto/create_invite_dto';
import {
  GetChartDataResponseDTO,
  GetChartDataSummaryResponseDTO,
  MethodChartDateFilterValues,
} from '@dash/types/index';
export interface OnboardingState {
  intermediateSessionToken?: string;
  mfaQRCode?: string;
}

export class MethodDashService {
  apiBase: string;
  _axios: AxiosInstance;

  constructor() {
    this.apiBase =
      process.env.NEXT_PUBLIC_BACKEND_API_URL || 'http://localhost:3001';
    this._axios = axios.create({
      baseURL: this.apiBase,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this._axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          // TODO: Better way to redirect? Cant useRouter in non-react components
          window.location.href = '/login';
        }
        return Promise.reject(error);
      }
    );

    this.refreshSessionInformation();
  }

  refreshSessionInformation() {
    if (authStore.getState().token) {
      this.setSessionInformation(
        authStore.getState().token,
        authStore.getState().selectedEnvironment
      );
    }
  }

  setSessionInformation(token: string, environment: MethodEnvironment) {
    this._axios.defaults.headers['Authorization'] = `Bearer ${token}`;
    this._axios.defaults.headers[HEADER_METHOD_ENVIRONMENT] = environment;
  }

  async createEntity(
    data: any
  ): Promise<MethodResponse<{ entity: IOctoEntity }>> {
    const response = await this._axios.post(`/data/entities`, data);
    return response.data;
  }

  async getEntity(
    id: string,
    expandAll = false
  ): Promise<MethodResponse<{ entity: IOctoEntity }>> {
    const params: Record<string, string[]> = {};
    if (expandAll) {
      params.expand = [
        'connect',
        'credit_score',
        'identity_latest_verification_session',
      ];
    }
    const response = await this._axios.get(`/data/entities/${id}`, { params });
    return response.data;
  }

  async getEntityProductsAndSubscriptions(
    id: string
  ): Promise<MethodResponse<GetEntityProductsAndSubscriptionsDTO>> {
    const response = await this._axios.get(
      `/data/entities/${id}/products_and_subscriptions`
    );
    return response.data;
  }

  async getEntities(
    params: Record<string, string | string[]> = {}
  ): Promise<MethodResponse<{ entity: IOctoEntity }[]>> {
    const response = await this._axios.get(`/data/entities`, { params });
    return response.data;
  }

  async getConnectProductsForEntity(
    entityId: string
  ): Promise<MethodResponse<IOctoGetConnectHistoryDTO>> {
    const response = await this._axios.get(
      `/data/entities/${entityId}/products/connect`
    );
    return response.data;
  }

  async getCreditScoresForEntity(
    entityId: string
  ): Promise<MethodResponse<IOctoGetCreditScoreHistoryDTO>> {
    const response = await this._axios.get(
      `/data/entities/${entityId}/products/credit_score`
    );
    return response.data;
  }

  async getIdentitiesForEntity(
    entityId: string
  ): Promise<MethodResponse<IOctoGetIdentityHistoryDTO>> {
    const response = await this._axios.get(
      `/data/entities/${entityId}/products/identity`
    );
    return response.data;
  }

  async executeProductForEntity(
    id: string,
    product: TOctoEntityProducts
  ): Promise<MethodResponse<IOctoExecuteAccountProductResponseDTO>> {
    const response = await this._axios.post(
      `/data/entities/${id}/execute_product`,
      { product }
    );
    return response.data;
  }

  async subscribeEntity(
    id: string,
    enroll: TOctoEntitySubscriptionTypes
  ): Promise<MethodResponse<IOctoSubscribeEntityResponseDTO>> {
    const response = await this._axios.post(`/data/entities/${id}/subscribe`, {
      enroll,
    });
    return response.data;
  }

  async getAccount(
    id: string,
    params: Record<string, string | string[]> = {}
  ): Promise<MethodResponse<IOctoGetAccountDTO>> {
    const response = await this._axios.get(`/data/accounts/${id}`, { params });
    return response.data;
  }

  async getAccountEnhanced(
    id: string
  ): Promise<MethodResponse<IOctoGetAccountEnhancedDTO>> {
    const response = await this._axios.get(`/data/accounts/${id}/enhanced`);
    return response.data;
  }

  async getAccountProductsAndSubscriptions(
    id: string
  ): Promise<MethodResponse<OctoGetAccountProductsAndSubscriptionsDTO>> {
    const response = await this._axios.get(
      `/data/accounts/${id}/products_and_subscriptions`
    );
    return response.data;
  }

  async getProductHistoryForAccount(
    id: string,
    product: string,
    filters: Record<string, string> = {}
  ): Promise<MethodResponse<IOctoGetProductHistoryForAccountDTO>> {
    const response = await this._axios.get(
      `/data/accounts/${id}/product_history`,
      { params: { product, filters } }
    );
    return response.data;
  }

  async getAccounts(
    params: Record<string, string | string[]> = {}
  ): Promise<
    MethodResponse<{ account: IOctoAccount; merchant: IOctoMerchant }[]>
  > {
    const response = await this._axios.get(`/data/accounts`, { params });
    return response.data;
  }

  async getAccountsAndProducts(
    ids: string[],
    products: TOctoProductNames[]
  ): Promise<MethodResponse<IOctoGetAccountsAndProductsResponse>> {
    const response = await this._axios.get(`/data/accounts_and_products`, {
      params: { ids, products },
    });

    return response.data;
  }

  async getAccountsOverview(
    ids: string[]
  ): Promise<MethodResponse<IOctoGetAccountsOverviewDTO>> {
    const response = await this._axios.get(`/data/accounts_overview`, {
      params: { ids },
    });

    return response.data;
  }

  async executeProductForAccount(
    id: string,
    product: TOctoProductNames,
    expand?: TOctoAccountSensitiveFields[]
  ): Promise<MethodResponse<IOctoExecuteAccountProductResponseDTO>> {
    const body: Record<string, any> = { product };
    if (expand) body.expand = expand;
    const response = await this._axios.post(
      `/data/accounts/${id}/execute_product`,
      body
    );
    return response.data;
  }

  async subscribeAccount(
    id: string,
    enroll: TOctoAccountSubscriptionTypes
  ): Promise<MethodResponse<IOctoSubscribeAccountResponseDTO>> {
    const response = await this._axios.post(`/data/accounts/${id}/subscribe`, {
      enroll,
    });
    return response.data;
  }

  async getPayment(id: string): Promise<MethodResponse<IOctoGetPaymentsDTO>> {
    const response = await this._axios.get(`/data/payments/${id}`);
    return response.data;
  }

  async getPayments(
    params?: Record<string, string | string[]>
  ): Promise<MethodResponse<IOctoGetPaymentsDTO[]>> {
    const response = await this._axios.get(`/data/payments`, { params });
    return response.data;
  }

  async getPaymentsOverview(
    params?: Record<string, string | string[]>
  ): Promise<MethodResponse<IOctoGetPaymentsOverviewDTO>> {
    const response = await this._axios.get(`/data/payments_overview`, {
      params,
    });
    return response.data;
  }

  // Charts
  async getEntitiesChartData(
    period: MethodChartDateFilterValues
  ): Promise<MethodResponse<GetChartDataResponseDTO>> {
    const params = { period };
    const response = await this._axios.get(`/charts/entities`, { params });
    return response.data;
  }

  async getEntitiesSummaryData(): Promise<
    MethodResponse<GetChartDataSummaryResponseDTO>
  > {
    const response = await this._axios.get(`/charts/entities_summary`);
    return response.data;
  }

  async getAccountsChartData(
    period: MethodChartDateFilterValues,
    product: TOctoAccountProducts
  ): Promise<MethodResponse<GetChartDataResponseDTO>> {
    const params = { period, product };
    const response = await this._axios.get(`/charts/accounts`, { params });
    return response.data;
  }

  async getAccountsSummaryData(): Promise<
    MethodResponse<GetChartDataSummaryResponseDTO>
  > {
    const response = await this._axios.get(`/charts/accounts_summary`);
    return response.data;
  }

  async getPaymentsChartData(
    period: MethodChartDateFilterValues,
    status?: string
  ): Promise<MethodResponse<GetChartDataResponseDTO>> {
    const params: Record<string, string> = { period };
    if (status) params.status = status;
    const response = await this._axios.get(`/charts/payments`, { params });
    return response.data;
  }

  async getPaymentsSummaryData(): Promise<
    MethodResponse<GetChartDataSummaryResponseDTO>
  > {
    const response = await this._axios.get(`/charts/payments_summary`);
    return response.data;
  }

  async getSettings(
    params?: Record<string, string | string[]>
  ): Promise<MethodResponse<GeneralSettings | TeamSettings[] | BillingSettings>> {
    const response = await this._axios.get(`/data/settings`, { params });
    return response.data;
  }

  async getDeveloperConfigs(
    params?: Record<string, string | string[]>
  ): Promise<
    MethodResponse<
      | DeveloperConfigAPIKeysDTO
      | DeveloperConfigWebhooksDTO
      | DeveloperConfigEventsDTO
    >
  > {
    const response = await this._axios.get(`/data/developers`, { params });
    return response.data;
  }

  async createDeveloperAPIKey(
    body?: Record<string, string | string[]>
  ): Promise<MethodResponse<any>> {
    const response = await this._axios.post(`/data/developers`, body);
    return response.data;
  }

  async inviteUser(body: CreateInviteValidatedDTO): Promise<void> {
    const response = await this._axios.post(`/invitations`, body);
    return response.data;
  }

  async resendInvite(email: string): Promise<void> {
    const response = await this._axios.put(`/invitations`, { email });
    return response.data;
  }

  async revokeInvite(email: string): Promise<void> {
    const response = await this._axios.delete(`/invitations`, {
      data: { email },
    });
    return response.data;
  }

  async updateMember(field: string, value: string | FormData): Promise<void> {
    let payload;
    let headers = {};
    if (value instanceof FormData) {
      payload = value;
      payload.append('field', field);
      headers = { 'Content-Type': 'multipart/form-data' };
    } else {
      payload = { field, value };
    }
    const response = await this._axios.put(`/members/me`, payload, {
      headers,
    });

    return response.data;
  }

  async updateOrg(field: string, value: string | FormData): Promise<void> {
    let payload;
    let headers = {};
    if (value instanceof FormData) {
      payload = value;
      payload.append('field', field);
      headers = { 'Content-Type': 'multipart/form-data' };
    } else {
      payload = { field, value };
    }
    const response = await this._axios.put(
      `/organizations/update_org`,
      payload,
      {
        headers,
      }
    );
    return response.data;
  }

  async requestNewMerchant(body: {
    name: string;
    liability_type: string;
    reason_for_request: string;
  }): Promise<void> {
    const response = await this._axios.post(
      `/organizations/request_new_merchant`,
      body
    );
    return response.data;
  }

  async getMerchant(id: string): Promise<MethodResponse<IOctoMerchantDetails>> {
    const response = await this._axios.get(`/data/merchants/${id}`);
    return response.data;
  }

  async getAccountsForMerchant(
    id: string
  ): Promise<MethodResponse<IOctoGetAccountDTO[]>> {
    const response = await this._axios.get(`/data/merchants/accounts/${id}`);
    return response.data;
  }

  private async downloadReport(reportType: string, body: any): Promise<void> {
    const response = await this._axios.post(
      `/data/${reportType}/table_export`,
      body,
      {
        responseType: 'blob',
      }
    );

    // Get filename from Content-Disposition header or use default
    const contentDisposition = response.headers['content-disposition'];
    const filename = contentDisposition
      ? contentDisposition.split('filename=')[1].replace(/['"]/g, '')
      : `${reportType}_export.csv`;

    // Create a download link and trigger it
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();

    // Cleanup
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  }

  async sendEntitiesReportRequest(body: any): Promise<void> {
    return this.downloadReport('entities', body);
  }

  async sendPaymentsReportRequest(body: any): Promise<void> {
    return this.downloadReport('payments', body);
  }

  async sendAccountsReportRequest(body: any): Promise<void> {
    return this.downloadReport('accounts', body);
  }

  async sendEntitiesReportMetadataRequest(body: any): Promise<{
    numRecords: number;
    type: string;
    startDate: string;
    endDate: string;
  }> {
    const response = await this._axios.post(
      `/data/entities/table_export_metadata`,
      body
    );
    return response.data;
  }

  async sendPaymentsReportMetadataRequest(body: any): Promise<{
    numRecords: number;
    type: string;
    startDate: string;
    endDate: string;
  }> {
    const response = await this._axios.post(
      `/data/payments/table_export_metadata`,
      body
    );
    return response.data;
  }

  async sendAccountsReportMetadataRequest(body: any): Promise<{
    numRecords: number;
    type: string;
    startDate: string;
    endDate: string;
  }> {
    const response = await this._axios.post(
      `/data/accounts/table_export_metadata`,
      body
    );
    return response.data;
  }

  async getIdentity(): Promise<any> {
    const response = await this._axios.get(`/members/me`);
    return response.data;
  }

  async getOrganizationMetadata(ids: string[]): Promise<any> {
    const response = await this._axios.get(`/organizations`, {
      params: { ids },
    });
    return response.data;
  }

  async getFilters(): Promise<any> {
    const response = await this._axios.get(`/organizations/filters`);
    return response.data;
  }
}
