import React, {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { MethodDashService } from '@frontend/services/methodDashService';

const MethodDashContext = createContext<MethodDashService | undefined>(
  undefined
);

export const MethodDashServiceProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [methodDashService] = useState(new MethodDashService());
  const value = useMemo(() => methodDashService, [methodDashService]);

  return (
    <MethodDashContext.Provider value={value}>
      {children}
    </MethodDashContext.Provider>
  );
};

export const useMethodDashService = () => {
  const context = useContext(MethodDashContext);
  if (context === undefined) {
    throw new Error('MethodDashContext must be used within a UserProvider');
  }
  return context;
};
